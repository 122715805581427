@import "./assets/theme/_var.scss";

body {
  margin: 0;
  font-family: $baseFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #fff;
  background: rgb(1, 1, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, 
h2,
h3, 
h4,
h5,
h6,
p {
  font-family: $baseFont, sans-serif;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}

// perfect scrollbar
.scrollbar-container {
  padding: 0 10px !important;
  .ps__thumb-y {
    background-color: $scrollColor !important;
    width: 3px !important;
  }
  .ps__rail-y {
    width: 7px !important;
  }
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background: transparent !important;
  border-radius: 8px;
  width: 6px !important;
}
.ps__thumb-x {
  background-color: $scrollColor !important;
  height: 3px !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: $scrollColor !important;
  width: 3px !important;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: $scrollColor !important;
  height: 3px !important;
}

::-webkit-scrollbar {
  width: 0px !important; 
  background: transparent !important;
}
